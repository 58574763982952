import {
    BanknotesIcon,
    HomeIcon,
    InboxStackIcon,
    UserCircleIcon,
    UsersIcon,
    UserGroupIcon,
} from "@heroicons/vue/24/outline/esm/index.js";

import {
    isCurrentPathExact,
    startsWithPath,
} from "~/utils/comparisons/pathComparisons";

export function useProviderNavigation() {
    const currentRoute = useRoute();
    const config = useRuntimeConfig();
    const sidebarOpen = ref(false); // Is this used anywhere? Seem to be using local refs
    const { t } = useI18n();

    const providerNavigation = computed (()=> [
        {
            name: t('home'),
            href: "/provider",
            pathRoot: "/provider",
            icon: HomeIcon,
            current: isCurrentPathExact("/provider", currentRoute.path),
            disabled: false,
        },
        {
            name: t('claims'),
            href: "/provider/claims",
            pathRoot: "/provider/claims/",
            icon: InboxStackIcon,
            current: startsWithPath("/provider/claims/", currentRoute.path),
            disabled: false,
        },
        {
            name: t('clients'),
            href: "/provider/clients/list/all",
            pathRoot: "/provider/clients/",
            icon: UsersIcon,
            current: startsWithPath("/provider/clients/", currentRoute.path),
            disabled: false,
        },
    ]);

    const providerAdminNavigation = ref([
        {
            name: "Payments",
            href: "/provider/payments/list/all",
            pathRoot: "/provider/payments/",
            icon: BanknotesIcon,
            current: startsWithPath("/provider/payments", currentRoute.path),
            disabled: false,
        },
        {
            name: "Payouts",
            href: "/provider/payouts/list/all",
            pathRoot: "/provider/payouts/",
            icon: BanknotesIcon,
            current: startsWithPath("/provider/payouts", currentRoute.path),
            disabled: config.public.NODE_ENV === "production" ? true : false,
        },
    ]);

    const providerClaimsListFilters = ref([
        {
            name: "All",
            href: "/provider/claims/list/all",
            current: startsWithPath(
                "/provider/claims/list/all",
                currentRoute.path
            ),
        },
        {
            name: "Paid",
            href: "/provider/claims/list/paid",
            current: startsWithPath(
                "/provider/claims/list/paid",
                currentRoute.path
            ),
        },
        {
            name: "Unpaid",
            href: "/provider/claims/list/unpaid",
            current: startsWithPath(
                "/provider/claims/list/unpaid",
                currentRoute.path
            ),
        },
        // { name: 'Active', href: '#', current: false },
        // { name: 'Overdue', href: '#', current: false },
    ]);

    const providerClientsListFilters = ref([
        {
            name: "All",
            href: "/provider/clients/list/all",
            current: startsWithPath(
                "/provider/clients/list/all",
                currentRoute.path
            ),
        },
        {
            name: "New",
            href: "/provider/clients/list/new",
            current: startsWithPath(
                "/provider/clients/list/new",
                currentRoute.path
            ),
        },
    ]);

    const providerPaymentsListFilters = ref([
        {
            name: "All",
            href: "/provider/payments/list/all",
            current: startsWithPath(
                "/provider/payments/list/all",
                currentRoute.path
            ),
        },
        {
            name: "New",
            href: "/provider/payments/list/new",
            current: startsWithPath(
                "/provider/payments/list/new",
                currentRoute.path
            ),
        },
        {
            name: "Client copays",
            href: "/provider/payments/list/copays",
            current: startsWithPath(
                "/provider/payments/list/copays",
                currentRoute.path
            ),
        },
        {
            name: "Insurance payments",
            href: "/provider/payments/list/insurance",
            current: startsWithPath(
                "/provider/payments/list/insurance",
                currentRoute.path
            ),
        },
    ]);

    const providerPayoutsListFilters = ref([
        {
            name: "All",
            href: "/provider/payouts/list/all",
            current: startsWithPath(
                "/provider/payouts/list/all",
                currentRoute.path
            ),
        },
        {
            name: "New",
            href: "/provider/payouts/list/new",
            current: startsWithPath(
                "/provider/payouts/list/new",
                currentRoute.path
            ),
        },
        {
            name: "This month",
            href: "/provider/payouts/list/thismonth",
            current: startsWithPath(
                "/provider/payouts/list/thismonth",
                currentRoute.path
            ),
        },
        {
            name: "Last month",
            href: "/provider/payouts/list/lastmonth",
            current: startsWithPath(
                "/provider/payouts/list/lastmonth",
                currentRoute.path
            ),
        },
        {
            name: "Older",
            href: "/provider/payouts/list/older",
            current: startsWithPath(
                "/provider/payouts/list/older",
                currentRoute.path
            ),
        },
    ]);

    watchEffect(() => {
        providerNavigation.value.forEach((item) => {
            if (item.name == "Home" || "Inicio") {
                item.current = isCurrentPathExact(
                    item.pathRoot,
                    currentRoute.path
                );
            } else {
                item.current = startsWithPath(item.pathRoot, currentRoute.path);
            }
        });
    });
    watchEffect(() => {
        providerClaimsListFilters.value.forEach((item) => {
            item.current = isCurrentPathExact(item.href, currentRoute.path);
        });
    });
    watchEffect(() => {
        providerPaymentsListFilters.value.forEach((item) => {
            item.current = isCurrentPathExact(item.href, currentRoute.path);
        });
    });
    watchEffect(() => {
        providerClientsListFilters.value.forEach((item) => {
            item.current = isCurrentPathExact(item.href, currentRoute.path);
        });
    });

    return {
        providerNavigation,
        providerAdminNavigation,
        providerClaimsListFilters,
        providerClientsListFilters,
        providerPaymentsListFilters,
        providerPayoutsListFilters,
        sidebarOpen,
    };
}
